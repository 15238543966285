import DataTable, { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { Report } from '@/models/Report';
import { Rpc } from '@/models/Rpc';
import { ReportFilters } from '@/models/UserFilter';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusColor, getStatusLabel, ReportStatusMap, sortedExcludePlanningStatuses } from '@/support/ReportStatus';
import { formatDate } from '@/support/String';
import { DateTime } from 'luxon';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { User, UserRole } from '@/models/User';

@Component<Dashboard>({})
export default class Dashboard extends Vue {
  // File structure for Typescript files in your Vue project
  // https://tutorials.atabix.com/frontend/typscript_file_structure/

  // #region @Props
  // #endregion

  // #region @Refs

  @Ref()
  protected reportDatatable!: DataTable;

  // #endregion

  // #region Class properties
  public $pageTitle = 'Dashboard Zelfopname';

  protected isLoading = true;

  protected statistics: Statistics | null = null;

  protected reportStatusMap: ReportStatusMap[] = sortedExcludePlanningStatuses();

  protected tableFilters: ReportFilters = {
    deadline_at_mode: 'all',
  };

  protected deadlineAanvragerStatus: {[key: string]: string}[] = [
    {
      name: 'Alle dossiers',
      value: 'all',
    },
    {
      name: 'Nog niet afgerond / Bijna op termijn',
      value: 'ongoing',
    },
    {
      name: 'Termijn verstreken',
      value: 'late',
    },
  ];
  // #endregion

  // #region Lifecycle Hooks / Init
  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;
    if (this.$store.state.Auth.hasRole([UserRole.ADMIN, UserRole.MANAGER])) {
      await this.fetchStatistics();
    }
    this.isLoading = false;
  }

  // #endregion

  // #region Class methods
  protected handleApplyFilter(filters: ReportFilters) {
    this.tableFilters = filters;

    setTimeout(() => this.reportDatatable?.refresh(), 500);
  }
  // #endregion

  // #region Async methods
  protected async fetchStatistics(): Promise<void> {
    const payload = {
      signature: 'mediator:dashboard',
      body: {},
    };

    try {
      const response = await new Rpc()
        .rpcPost(payload, false);

      this.statistics = response?.data || null;
    } catch (error) {
      ErrorHandler.network(error);
    }
  }
  // #endregion

  // #region Getters & Setters
  protected get tableOptions(): TableOptions {
    return {
      model: new Report()
        .filter({ opname_variants: ['zelf_opname'] })
        .filter(this.dataTableFilters),
      headers: [
        {
          text: 'Zaaknummer',
          value: 'case_number',
          action: '/reports/{id}',
          sortable: {
            key: 'case_number',
            order: 'ASC',
          },
        },
        {
          text: 'Zaakbegeleiders',
          value: 'mediator',
          transform: (mediator: User) => mediator?.name || '',
        },
        {
          text: 'Opnamedatum',
          value: 'assigned_at',
          width: '150px',
          transform: (assigned_at: string) => {
            if (assigned_at === '0000-00-00 00:00:00') {
              return '';
            }
            return formatDate(assigned_at, 'dd-LL-yyyy HH:mm');
          },
        },
        {
          text: 'Deadline aanvrager',
          value: 'assigned_at',
          width: '150px',
          transform: (assigned_at: string, report: Report) => {
            if (assigned_at === '0000-00-00 00:00:00') {
              return '';
            }

            const deadlineNumber = report.stats_applicant_deadline_in_days as number;

            let deadlineNumberClass = '';
            let deadlineNumberType = '';

            if (deadlineNumber < 0) {
              deadlineNumberClass = 'negativeNumber';
            } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
              deadlineNumberClass = 'warningNumber';
              deadlineNumberType = '+';
            } else {
              deadlineNumberClass = 'positiveNumber';
              deadlineNumberType = '+';
            }

            return `<span class="deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${deadlineNumber}</span>`;
          },
        },
        {
          text: 'Status',
          value: 'status',
          transform: (status: string) => {
            if (! status) {
              return '';
            }
            return `<span class="statusPill statusBackgroundColor--${getStatusColor(status)}">${getStatusLabel(status)}</span>`;
          },
        },
      ],
    };
  }

  protected get visibility(): TableVisibility {
    return {
      search: true,
      title: false,
      checkboxes: false,
    };
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }
  // #endregion

  // #region @Watchers
  // #endregion
}

interface Statistics {
  late: number;
  ongoing: number;
  total: number;
}
