var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Dashboard Zelfopname")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", md4: "", "text-center": "" } },
                          [
                            _c("StatisticsNumberWidget", {
                              attrs: {
                                item: {
                                  class: "",
                                  amount: _vm.statistics.total,
                                  description: "Totaal aantal dossiers"
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", md4: "", "text-center": "" } },
                          [
                            _c("StatisticsNumberWidget", {
                              attrs: {
                                item: {
                                  class: "number-color--orange",
                                  amount: _vm.statistics.ongoing,
                                  description:
                                    "Nog niet afgerond / bijna op termijn"
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "", md4: "", "text-center": "" } },
                          [
                            _c("StatisticsNumberWidget", {
                              attrs: {
                                item: {
                                  class: "number-color--red",
                                  amount: _vm.statistics.late,
                                  description: "Termijn verstreken"
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { "align-baseline": "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("ReportMediatorFilter", {
                                  model: {
                                    value: _vm.tableFilters.mediators,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tableFilters,
                                        "mediators",
                                        $$v
                                      )
                                    },
                                    expression: "tableFilters.mediators"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("v-select", {
                                  staticClass: "with-full-border",
                                  attrs: {
                                    items: _vm.deadlineAanvragerStatus,
                                    "item-value": "value",
                                    "item-text": "name",
                                    "hide-details": "",
                                    placeholder: "Deadline aanvrager status"
                                  },
                                  model: {
                                    value: _vm.tableFilters.deadline_at_mode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tableFilters,
                                        "deadline_at_mode",
                                        $$v
                                      )
                                    },
                                    expression: "tableFilters.deadline_at_mode"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("ReportStatusFilter", {
                                  attrs: {
                                    items: _vm.reportStatusMap,
                                    canSelectAll: true
                                  },
                                  model: {
                                    value: _vm.tableFilters.statuses,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tableFilters,
                                        "statuses",
                                        $$v
                                      )
                                    },
                                    expression: "tableFilters.statuses"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "custom-filter--userFilterReports",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("UserFilter", {
                                  ref: "userFilter",
                                  attrs: {
                                    filterType:
                                      "zaakbegeleiding-zelfopname-dashboard",
                                    filters: _vm.tableFilters
                                  },
                                  on: {
                                    selectedUserFilters: _vm.handleApplyFilter
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { x12: "" } },
                      [
                        _c("DataTable", {
                          ref: "reportDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            visibility: _vm.visibility
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }